import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import authAPI from '@/api/auth'
import Cookie from "js-cookie";

Vue.use(Router);

const uuid4Regex = `[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}`

let accountTypeRegex = `staff|student|parent`

const setReturnRouteNameParam = (to, from, defaultRouteName) => {
    if(from.name === undefined || from.name === null) {
        to.params.returnRouteName = defaultRouteName
    } else {
        to.params.returnRouteName = from.name
    }
}

const router = new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        // new routes
        {
            path: '/product-list',
            name: 'product-list',
            component: () => import(/* webpackChunkName: "product-list" */ "./views/ProductList"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/',
            name: "default-layout",
            props: true,
            component: () => import(/* webpackChunkName: "default-layout" */ "./layouts/Default"),
            meta: {
                guest: true,
                validateClientId: true,
                legacyClientIdRedirect: true,
            },
            children: [
                {
                    // safer to have this first since all the other routes are dynamic, regex should prevent order
                    // from not mattering though
                    path: '/change-password/:token',
                    name: 'change-password',
                    props: true,
                    component: () => import("./views/ChangePassword"),
                },
                {
                    path: '/',
                    name: 'district-select',
                    props: true,
                    component: () => import(/* webpackChunkName: "district-select" */ "./views/DistrictSelect"),
                    children: [
                        {
                            path: `:districtId(${uuid4Regex})`,
                            name: "account-type-select",
                            props: true,
                            component: () => import(/* webpackChunkName: "district-select" */ "./views/AccountTypeSelect"),
                        },
                    ]
                },
                {
                    path: `/:districtId(${uuid4Regex})/:accountType(${accountTypeRegex})`,
                    props: true,
                    component: () => import(/* webpackChunkName: "login-form" */ "./views/Login"),
                    children: [
                        {
                            path: '',
                            name: "login",
                            props: true,
                            component: () => import(/* webpackChunkName: "password-login" */ "./components/PasswordLogin")
                        },
                        {
                            path: 'reset-password',
                            name: 'reset-password',
                            props: true,
                            beforeEnter: (to, from, next) => {
                                setReturnRouteNameParam(to, from, 'login')
                                next()
                            },
                            component: () => import(/* webpackChunkName: "password-reset" */"./components/PasswordReset"),
                        },
                    ]
                },
            ],
        },
        {
            path: '/error',
            name: 'error',
            component: () => import(/* webpackChunkName: "error" */ "./views/Error"),
        },
        {
            path: '*',
            name: 'not-found',
            redirect: {name: 'district-select'},
        }
    ]
});

// middlewares
// TODO: can we abstract this out instead of just adding on like below?
router.beforeEach(async (to, from, next) => {
    // clear messages if we originate from an internal route
    if (from.name) {
        store.dispatch('setError', "")
        store.dispatch('setSuccess', "")
    }

    // look for new requestId
    // also, lets remove it from the url so we can't copy it easily, causing potential issues from copy/paste
    if (to.query.req && to.query.req !== store.getters.requestId) {
        store.dispatch('setRequestId', to.query.req)
        delete to.query.req

        return next(to)
    }

    // look for error query, and remove after adding to error stack
    if (to.query.error) {
        store.dispatch('setError', to.query.error)
        delete to.query.error

        return next(to)
    }

    // auth required
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.userinfo == null) {
            await store.dispatch('getUserInfo');

            if (!store.getters.signedIn) {
                if (store.getters.isClientSelected) {
                    return next({
                        name: 'account-type-select',
                        params: {
                            districtId: store.getters.selectedClient.id
                        },
                        query: to.query
                    })
                }
                return next({
                    name: 'district-select',
                    query: to.query
                })
            }
        }

        store.dispatch('startTimer');
    }

    // must be guest, otherwise redirect to product list
    if (to.matched.some(record => record.meta.guest)) {
        let opbsCookie = Cookie.get('opbs');

        // redirect user to product-list page on page load when logged in
        // if we have signedIn set, redirect right away, otherwise check to
        // see if an obps cookie is set, and if so fetch the userinfo and
        // redirect if the user has a session
        if (store.getters.signedIn) {
            return next({
                name: 'product-list',
                query: to.query,
            })
        } else if (opbsCookie !== undefined) {
            await store.dispatch('getUserInfo')
            if (store.getters.signedIn) {
                return next({
                    name: 'product-list',
                    query: to.query,
                })
            }

            Cookie.remove('opbs');
        }

        // redirect user to product list page when they are logged in
        // this solves having multiple tabs open and other pages not redirecting
        // to the product list page once logged in on guest pages
        let checkLoggedIn = setInterval(() => {
            if (store.getters.signedIn) {
                clearInterval(checkLoggedIn);
                return next({
                    name: 'product-list',
                    query: to.query,
                })
            } else if (opbsCookie != Cookie.get('opbs')) {
                location.reload();
                return;
            }
        }, 5*1000);

        // if we got here, it means no redirects happened and the user is not logged in.
        // fetch the illuminator login connector from state
        await store.dispatch('fetchIlluminateConnector')
    }

    // validate clientId
    if (to.matched.some(record => record.meta.validateClientId)) {
        let clientId = to.params.districtId

        // only check if we have a districtId param
        if (clientId !== undefined) {
            if (!store.getters.selectedClient || clientId !== store.getters.selectedClient.id) {
                try {
                    let client = await authAPI.getClient(clientId)
                    await store.dispatch('setClient', client)
                } catch (err) {
                    await store.dispatch('setClient', null)
                    return next('district-select')
                }
            }
        }
    }

    // legacy query redirect
    if (to.matched.some(record => record.meta.legacyClientIdRedirect)) {
        let clientId = to.query.ie_client_id
        let accountType = to.query.account_type
        delete to.query.ie_client_id
        delete to.query.account_type
        
        if (clientId) {
            if (accountType && accountType.match(new RegExp(accountTypeRegex)) != null) {
                return next({
                    name: 'login',
                    params: {districtId: clientId, accountType: accountType},
                    query: to.query
                })
            }    

            return next({
                name: 'account-type-select',
                params: {districtId: clientId},
                query: to.query
            })
        }
    }

    return next()
});

export default router;
